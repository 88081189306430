<script setup lang="ts">
import { cn } from '@laam/lib/utils';
import { PhX } from '@phosphor-icons/vue';
import {
	DialogClose,
	DialogContent,
	DialogOverlay,
	DialogPortal,
	useForwardPropsEmits,
	type DialogContentEmits,
	type DialogContentProps,
} from 'radix-vue';
import { computed, type HTMLAttributes } from 'vue';

const props = withDefaults(
	defineProps<
		DialogContentProps & {
			class?: HTMLAttributes['class'];
			side?: string;
			showClose?: boolean;
			showIconWithBg?: boolean;
			classZIndex?: string;
		}
	>(),
	{
		class: '',
		side: 'center',
		showClose: true,
		showIconWithBg: false,
		classZIndex: '',
	},
);
const emits = defineEmits<DialogContentEmits>();

const delegatedProps = computed(() => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { class: _, ...delegated } = props;

	return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
	<DialogPortal>
		<DialogOverlay
			class="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
			:class="delegatedProps.classZIndex"
		/>
		<DialogContent
			v-bind="forwarded"
			:class="[
				cn(
					'fixed left-1/2  z-50 grid -translate-x-1/2  gap-3xl border border-gray-200 bg-white p-7xl shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-large',
					props.class,
					delegatedProps.classZIndex,
				),
				{
					'top-1/2 -translate-y-1/2': side == 'center',
					'bottom-3xl': side == 'bottom',
				},
			]"
			:data-testid="$attrs['data-testid']"
		>
			<slot name="headerIcons" />
			<slot />

			<DialogClose
				v-if="delegatedProps.showClose"
				class="absolute right-3xl top-3xl rounded-small opacity-70 ring-offset-white transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-neutral-950 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-neutral-100 data-[state=open]:text-neutral-50"
				data-testid="close-dialog-button"
			>
				<div
					v-if="showIconWithBg"
					class="p-xs rounded-full border-slate-200 border-opacity-50 border-spacing-sm border-2 bg-slate-200 cursor-pointer"
				>
					<PhX size="18" :color="'#000000'" :weight="'bold'" />
				</div>
				<PhX v-else class="w-7xl h-7xl" />
				<span class="sr-only">Close</span>
			</DialogClose>
		</DialogContent>
	</DialogPortal>
</template>
